import React from "react";

import * as S from "./styles";

import { withRouter, useHistory } from "react-router-dom";
import Gif from "../../assets/img/check-booking-ok.gif";
import Attention from "../../assets/img/attention.png";

import { Typography } from "@material-ui/core";
import ThemeButton from "../../components/ThemeButton";

import { getSessionStorage } from "../../utils/getSessionStorage";

function BookingFinished({ type, searchState, buscaAtual }) {
	let history = useHistory();
	const booking = getSessionStorage("@bookingCompleted");
	// const booking = {};
	// sessionStorage.setItem('@bookingCompleted', JSON.stringify(objInsert));
	// sessionStorage.setItem('buscaAtual', 'Aéreo');
 	const bookingCode =
			booking?.data?.bookingResponse?.bookingCode ||
			booking?.data?.data?.bookingResponse?.bookingCode;
		const bookingError = booking?.data?.error || booking?.data?.data?.error;
		return (
			<S.Wrapper>
				{booking?.status === 200 ||
					(booking?.success === true && (
						<>
							<img src={Gif} alt='Compra concluida' />
							<Typography variant='h3'>Reserva concluída</Typography>
							<Typography variant='span'>Código da Reserva</Typography>

							<Typography variant='h2'>{bookingCode}</Typography>
						</>
					))}
				{booking?.status === 201 && (
					<>
						<img src={Attention} alt='Reserva efetuada mas não finalizada' />
						<Typography variant='h3'>
							Sua reserva foi concluida com ressalvas
						</Typography>

						<Typography variant='span'>{bookingError}</Typography>
						<Typography variant='span' color='error'>
							Código da Reserva
						</Typography>

						<Typography variant='h2'>{bookingCode}</Typography>

						<Typography variant='span'>
							Vá até Minhas viagens, insira os dados do cartão, para podermos concluir
							a reserva
						</Typography>
					</>
				)}
				<ThemeButton
					onClick={() => history.push('/conta/minhas-viagens')}
					className='pesquisa'
				>
					Ver minhas viagens
				</ThemeButton>
			</S.Wrapper>
		);
}

export default withRouter(BookingFinished);
