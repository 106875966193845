import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
  `}
`;

export const NotFound = styled.div`
  ${({ theme }) => css`
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 800;
    color: #545454;
    flex-direction: column;
    min-height: calc(100vh - 510px);

    .pesquisa {
      margin-top: 50px;
      height: 50px;
    }
  `}
`;

