import { useEffect, useState } from "react";

import * as S from "./styles";

import Separator from "../../Separator";
import { useGlobal } from "../../../contexts/globalContext";
import Alert from "@material-ui/lab/Alert";
import { getSessionStorage } from "../../../utils/getSessionStorage";

const EntertainmentSideSummary = ({ userPoints, setPointsPrice, minimumPointsToBuy, paymentInPoints, paymentInMoney }) => {
	const { signed, user } = useGlobal();
	const [coinAlias, setCoinAlias] = useState("Pontos");

	const selectedActivity = getSessionStorage("@EntertainmentSelected");

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || "Pontos");
		setPointsPrice(selectedActivity?.data?.totalPrice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedActivity, user?.campaign?.campaignConfig?.coinAlias]);

	const Header = ({ type, date, ...props }) => {
		return (
			<S.SideHeader {...props}>
				<div>
					<span>{type}</span>
				</div>
				<span className="date-header">{date !== undefined && date !== "Invalid Date" && date}</span>
			</S.SideHeader>
		);
	};

	const SubHeader = ({ type, hour, ...props }) => {
		return (
			<S.SideHeader {...props}>
				<div>
					<span>{type}</span>
				</div>
				<span className="date-header">{hour !== undefined && hour !== "Invalid Hour" && hour}</span>
			</S.SideHeader>
		);
	};

	return (
		<div>
			<Header className="first" type="ENTRADA" date={selectedActivity?.data.serviceDate} />
			<S.BlankCardOnly></S.BlankCardOnly>
			{selectedActivity?.data?.startingTimes && selectedActivity.data.startingTimes.length > 0 ? (
				<SubHeader type="HORÁRIO" hour={selectedActivity.data.selectedTime} />
			) : null}
			{selectedActivity?.data?.returnDate && selectedActivity.data.returnDate !== undefined ? (
				<Header type="RETORNO" date={selectedActivity?.data.returnDate} />
			) : null}
			<Separator className="solid" />
			<S.BlankCardOnly>
				<S.PagamentoLabel>Pagamento</S.PagamentoLabel>
				<Separator className="solid thin" margin={15} />
				<S.BlankCardOnly>
					<S.Total className="total-pontos">
						<span className="bold total">Valor do Saldo</span>
						<span className="bold">{paymentInPoints}</span>
					</S.Total>
					{user?.campaign?.acceptCashPayment && (
						<S.Total className="total-reais">
							<span className="bold total">Valor adicional em R$</span>
							<span className="bold total">
								{new Intl.NumberFormat("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).format(paymentInMoney)}
							</span>
						</S.Total>
					)}
				</S.BlankCardOnly>
				{signed && userPoints < minimumPointsToBuy && (
					<S.AlertStyle>
						<Alert variant="filled" severity="warning">
							<span className="text">
								Você deve possuir ao menos <br />
								<span className="value">
									{minimumPointsToBuy} {coinAlias}
								</span>{" "}
								para realizar esta compra!
							</span>
						</Alert>
					</S.AlertStyle>
				)}
			</S.BlankCardOnly>
		</div>
	);
};

export default EntertainmentSideSummary;
