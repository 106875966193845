import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

import CarCard from './CarCard';

import * as S from './styles';


const CarList = ({ filters, searchState, results, loading }) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    let res = results;

    if (
      filters?.filters?.rentalCias?.length > 0 &&
      filters?.filters?.rentalCias !== undefined
    ) {
      res = res?.filter(
        (f) => filters?.filters?.rentalCias?.indexOf(f?.companyCode) !== -1
      );
    }

    if (
      filters?.filters?.carType?.length > 0 &&
      filters?.filters?.carType !== undefined
    ) {
      res = res?.filter(
        (f) => filters?.filters?.carType?.indexOf(f?.category) !== -1
      );
    }

    res = res?.filter(
      (f) =>
        f?.totalAmount <= filters?.filters?.amount?.valorFinal &&
        f?.totalAmount >= filters?.filters?.amount?.valorInicial
    );

    setResult(res);
  }, [filters, results]);

  return (
    <div>
      <S.Wrapper>
        <Grid container>
          <Grid item xs={12} md={12} lg={10}>
            <Grid container>
              {loading ? (
                <Grid container spacing={3}>
                  {[...Array(5)].map((_, index) => (
                    <Grid item xs={12} md={12} lg={10} key={index}>
                      <Skeleton variant="rect" width="100%" height={200} />
                    </Grid>
                  ))}
                </Grid>
              ) : result.length > 0 ? (
                result
                  ?.sort((a, b) => a?.totalAmount - b?.totalAmount)
                  ?.map((car, index) => (
                    <Grid item xs={12} key={index}>
                      <CarCard car={car} searchState={searchState} />
                    </Grid>
                  ))
              ) : (
                <S.NotFound>
                  Não encontramos resultados que correspondam aos filtros selecionados. <br />
                  Tente ajustar os critérios de busca para obter mais opções.
                </S.NotFound>
              )}
            </Grid>
          </Grid>
        </Grid>
      </S.Wrapper>
    </div>
  );
};

export default CarList;
